<template>
  <div>
    <div
      v-b-modal.select-recharge-type
      tabindex="-1"
      class="service-card shadow-sm"
      :class="airtimeRechargeMode === true ? 'active' : null"
      @click="SET_AIRTIME_RECHARGE(true)"
    >
      <i class="fa fa-2x fa-phone mb-1" />
      Recargas de tiempo aire
    </div>
    <!-- Show the services type -->
    <b-modal
      id="select-recharge-type"
      title="Selecciona una opción"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-row>
        <b-col>
          <b-button
            v-for="(service, key) in otherProducts"
            :key="`service-${key}`"
            v-b-modal.select-recharge-supplier
            :variant="serviceData === service ? 'info' : 'primary'"
            block
            class="my-1"
            size="lg"
            @click="serviceData = service"
          >
            {{ key }}
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            variant="outline-danger"
            @click="resetData('select-recharge-type')"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Show the suppliers for the service selected -->
    <b-modal
      id="select-recharge-supplier"
      title="Selecciona una opción"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-title="Cancelar"
      ok-only
      ok-variant="outline-danger"
      @ok="resetData('select-recharge-supplier')"
    >
      <b-row class="d-flex justify-content-center">
        <b-col>
          <b-button
            v-for="(supplierService, supplierServiceName) in serviceData"
            :key="`service-${supplierServiceName}`"
            v-b-modal.select-supplier-options
            :variant="
              supplierServiceData === supplierService ? 'info' : 'primary'
            "
            block
            class="my-1"
            size="lg"
            @click="supplierServiceData = supplierService"
          >
            {{ supplierServiceName }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Show the options for the service > supplier selected -->
    <b-modal
      id="select-supplier-options"
      title="Selecciona una opción y da click en continuar"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-row class="d-flex justify-content-center">
        <b-col>
          <b-button
            v-for="option in supplierServiceData"
            :key="`option-${option.id}`"
            :variant="selectedOption === option ? 'info' : 'primary'"
            block
            class="my-1"
            size="lg"
            @click="selectedOption = option"
          >
            {{ option.carrier_name }} - {{ option.product_name }}
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click="resetData('select-supplier-options')">
          Cancelar
        </b-button>
        <b-button
          :disabled="selectedOption === null"
          :variant="selectedOption === null ? 'outline-primary' : 'success'"
          @click="fectchAndOpenModal()"
        >
          OK
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="payment-modal"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-row class="d-flex justify-content-center">
        <b-col v-if="selectedProduct !== null" class="py-4">
          <div class="display-1 text-success text-center">
            ${{ Number(selectedProduct.amount).toFixed(2) }}
          </div>
          <h4 class="text-center">Total a pagar por la transacción</h4>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            variant="outline-danger"
            @click="resetData('payment-modal')"
          >
            Cancelar
          </b-button>
          <b-button
            variant="success"
            @click="$bvModal.show('complete-codi-payment-for-phone-credits')"
          >
            Continuar
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Select Payment method -->
    <b-modal
      id="complete-codi-payment-for-phone-credits"
      title="Pago con CoDi"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <validation-observer ref="accountCelRules" tag="form">
        <b-row>
          <b-col
            class="
              mb-2
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
            cols="12"
          >
            <b-img
              :src="require('@/assets/images/logo/CODI.svg')"
              width="150"
            />
            <h5 class="mb-0">Número de teléfono</h5>
            <small class="text-muted"
              >Agrega tu número de teléfono para confirmar la operación</small
            >
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="v-cel_number">
              <validation-provider
                #default="{ errors }"
                name="Celular"
                rules="required|digits:10"
              >
                <b-form-input
                  id="v-cel_number"
                  v-model="emida_product.cel_number"
                  placeholder="Celular a 10 dígitos"
                  size="lg"
                  type="number"
                  class="text-center"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <b-col cols="12" class="d-flex justify-content-center align-items-center">
        <b-button v-b-modal.codi-info-for-phone-credits variant="link">
          ¿Cómo activar CoDi? <em class="fas fa-info-circle" />
        </b-button>
      </b-col>
      <template #modal-footer>
        <b-button @click="resetData('complete-codi-payment-for-phone-credits')">
          Cancelar
        </b-button>
        <b-button
          :disabled="
            !(
              emida_product.cel_number &&
              String(emida_product.cel_number).length === 10
            )
          "
          :variant="
            !(
              emida_product.cel_number &&
              String(emida_product.cel_number).length === 10
            )
              ? 'outline-primary'
              : 'success'
          "
          @click="handleOpenFinishModalPhoneCredits()"
        >
          OK
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="codi-info-for-phone-credits"
      title="Conoce como activar CoDi"
      ok-only
      ok-title="Cancelar"
      ok-variant="secondary"
      @ok="$bvModal.show('complete-codi-payment-for-services')"
    >
      <b-row class="d-flex justify-content-center">
        <b-col sm="auto" class="d-flex flex-column">
          <b-button
            href="https://youtu.be/Ywx-aXuqqUI"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            BBVA
          </b-button>
          <b-button
            href="https://youtu.be/UP1RvwyvmLA"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Citibanamex
          </b-button>
          <b-button
            href="https://youtu.be/pfAvXmyZ8xQ"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Santander
          </b-button>
          <b-button
            href="https://youtu.be/ELo20ro2GyE"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Banorte
          </b-button>
          <b-button
            href="https://www.scotiabank.com.mx/servicios-bancarios/codi.aspx"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Scotiabank
          </b-button>
          <b-button
            href="https://www.hsbc.com.mx/content/dam/hsbc/mx/documents/digital/hsbc-mexico/registro_codi_hsbc_mexico.pdf"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            HSBC
          </b-button>
          <b-button
            href="https://www.banregio.com/codi.php"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Banregio
          </b-button>
          <b-button
            href="https://www.bancoppel.com/modal_bcopp/guia_codi_activacion_movil.html"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Bancoppel
          </b-button>
          <b-button
            href="https://www.bancoazteca.com.mx/conocenos/uso-de-codi.html"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Banco Azteca
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      v-if="selectedProduct"
      id="finish-transaction"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <b-row class="d-flex justify-content-center">
        <b-col sm="auto">
          <p class="d-flex flex-column">
            <span class="text-muted"> Información: </span>
            {{ selectedProduct.references[0].tool_tip }}
          </p>
          <validation-observer
            #default="{ handleSubmit }"
            ref="rechargeFormObserver"
          >
            <!-- Account Form -->
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <validation-provider
                #default="validationContext"
                name="account"
                rules="required|min:10"
              >
                <b-form-group
                  :label="selectedProduct.references[0].reference_name"
                  label-for="account"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <i class="fas fa-mobile" />
                    </b-input-group-prepend>

                    <!-- Comentario -->
                    <b-form-input
                      id="account"
                      v-model="emida_product.account_id"
                      :state="getValidationState(validationContext)"
                      autocomplete="off"
                      trim
                      :formatter="formatter"
                      @input="phoneSizeValidation()"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="account"
                rules="required|min:10"
              >
                <b-form-group
                  :label="selectedProduct.references[0].reference_name"
                  label-for="confirmation"
                >
                  <!-- Validate phone number -->
                  <b-input-group class="mt-2">
                    <b-input-group-prepend is-text>
                      <i class="fas fa-mobile" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="confirmation"
                      v-model="phoneValidation"
                      :state="getValidationState(validationContext)"
                      autocomplete="off"
                      trim
                      :formatter="formatter"
                      @input="phoneSizeValidation()"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-row class="d-flex justify-content-between">
                <b-col sm="auto">
                  <b-button
                    variant="outline-danger"
                    @click="resetData('finish-transaction')"
                  >
                    Cancelar
                  </b-button>
                </b-col>
                <b-col sm="auto">
                  <b-button
                    v-if="toggleDisable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="mr-2"
                    type="submit"
                    :disabled="loading"
                  >
                    <b-spinner v-if="loading" small />
                    Confirmar
                  </b-button>
                  <b-button v-if="!toggleDisable" class="mr-2">
                    Confirmar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup({ emit }) {
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      emit,
      required,
    };
  },
  data() {
    return {
      loading: false,
      toggleDisable: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      serviceData: null,
      supplierServiceData: null,
      selectedOption: null,
      selectedProduct: null,
      phoneValidation: null,
      emida_product: {
        product_id: null,
        account_id: null,
        cel_number: null,
        order_payments_attributes: [
          {
            payment_type: null,
            amount: null,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("stores", ["currentStore"]),
    ...mapGetters("pos", ["airtimeRechargeMode"]),
    ...mapGetters("emidaProducts", ["otherProducts"]),
  },
  mounted() {
    this.toggleDisable = false;
    this.emidaConnection = new WebSocket(
      `${process.env.VUE_APP_WSS_URL}/cable?token=${this.userData.token}`
    );
    sessionStorage.setItem(
      "wsConnectionEmida",
      JSON.stringify(this.emidaConnection)
    );

    this.emidaConnection.onmessage = (event) => {
      const messagex = JSON.parse(event.data);
      if (typeof messagex.message === "object") {
        this.$swal({
          title: messagex.message.title,
          text: messagex.message.content_message,
          icon: "warning",
          showCancelButton: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    };

    this.emidaConnection.onopen = () => {
      // eslint-disable-next-line
      console.log("Successfully connected to the echo websocket server...");

      // eslint-disable-next-line
      this.emidaConnection.send(
        JSON.stringify({
          command: "subscribe",
          identifier: '{"channel":"ApplicationCable::MyChannel"}',
        })
      );
    };
  },
  destroyed() {
    /**
     * When the view has change destroyed the webSocket connection
     * */
    this.emidaConnection.close();
  },
  methods: {
    ...mapMutations("pos", ["pushProductToCart", "SET_AIRTIME_RECHARGE"]),
    ...mapActions("emidaProducts", ["fetchEmidaProduct", "purchaseTypeA"]),
    fectchAndOpenModal() {
      this.fetchEmidaProduct(this.selectedOption.id).then((response) => {
        this.selectedProduct = response;
        this.$bvModal.show("payment-modal");
      });
    },
    formatter(value) {
      const temp = value.match(/[0-9]+/g).join("");
      const temp2 = temp.replace(/[a-zA-Z]/g, "");
      return temp2.substring(0, 10);
    },
    phoneSizeValidation() {
      if (
        this.emida_product.account_id.length === 10 &&
        this.phoneValidation.length === 10
      ) {
        if (this.emida_product.account_id === this.phoneValidation) {
          this.toggleDisable = true;
        }
      } else {
        this.toggleDisable = false;
      }
    },
    eliminarDiacriticos(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    resetData(id) {
      this.SET_AIRTIME_RECHARGE(false);
      this.serviceData = null;
      this.supplierServiceData = null;
      this.selectedOption = null;
      this.selectedProduct = null;
      this.phoneValidation = null;
      this.emida_product = {
        product_id: null,
        account_id: null,
        cel_number: null,
        store_clerk_id: null,
        order_payments_attributes: [
          {
            payment_type: null,
            amount: null,
          },
        ],
      };
      this.$bvModal.hide(id);
    },
    onSubmit() {
      this.$refs.rechargeFormObserver.validate().then((success) => {
        if (success) {
          if (this.emida_product.account_id === this.phoneValidation) {
            this.loading = true;
            this.emida_product.product_id = this.selectedProduct.product_id;
            (this.emida_product.order_payments_attributes = [
              {
                payment_type: "codi",
                amount: this.selectedProduct.amount,
              },
            ]),
              this.purchaseTypeA(this.emida_product)
                .then((response) => {
                  if (response.status !== 202) {
                    this.$swal({
                      title: "Cobro solicitado!",
                      text: "En unos momentos más llegará una notificación en tu aplicación bancaria. Autoriza el cobro en tu celular para poder procesar tu compra",
                      icon: "success",
                      customClass: {
                        confirmButton: "btnx btn-primary",
                      },
                      buttonsStyling: false,
                    });
                  } else {
                    this.$swal({
                      title: "¡Compra pendiente!",
                      text: response.data.messages[0],
                      icon: "warning",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    });
                  }
                  this.loading = false;
                  this.resetData();
                  this.$bvModal.hide("finish-transaction");
                })
                .catch((error) => {
                  this.loading = false;
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: "Error",
                      icon: "CoffeeIcon",
                      variant: "danger",
                      text: error.response.data.messages[0],
                    },
                  });
                  this.resetData();
                  this.$bvModal.hide("finish-transaction");
                });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error",
                icon: "CoffeeIcon",
                variant: "danger",
                text: "Revisa los números telefónicos no coinciden",
              },
            });
          }
        }
      });
    },
    handleOpenFinishModalPhoneCredits() {
      this.$bvModal.show("finish-transaction");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.active {
  background-color: $primary !important;
}

.codi-button {
  background-color: #444c66;
}
</style>
