<template>
  <div class="service-grid">
    <services-payment-online />
    <prepaid-phone-credits-online />
  </div>
</template>

<script>
import ServicesPaymentOnline from "@/views/pos/services/ServicesPaymentOnline.vue";
import PrepaidPhoneCreditsOnline from "@/views/pos/services/PrepaidPhoneCreditsOnline.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ServicesPaymentOnline,
    PrepaidPhoneCreditsOnline,
  },
  mounted() {
    this.fetchEmidaProducts();
  },
  methods: {
    ...mapActions("emidaProducts", ["fetchEmidaProducts"]),
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  .service-grid {
    .service-card {
      background-color: $theme-dark-card-bg;
      color: white;
    }
  }
}

.service-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  .service-card {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 16px;
    text-align: center;
    padding: 1rem;
    background-color: white;
    cursor: pointer;
    transition: transform 0.2s ease;
    &:hover {
      transform: scale(108%);
    }
  }
}
</style>
