<template>
  <div>
    <b-modal
      id="bookStoreModal"
      :visible="bookStoreModal"
      title="Ingresa los datos a continuación"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <h5 class="px-1 py-1">
        Cuando añades un producto o servicio de este tipo al carrito, es
        imprescindible proporcionar el nombre de la persona a la que va dirigido
      </h5>
      <b-badge
        v-if="storeData.store_type === 'book_store'"
        pill
        :variant="'light-warning'"
      >
        {{ "Recuerda: los libros se recogeran en la escuela," }}
      </b-badge>
      <b-badge
        v-if="storeData.store_type === 'book_store'"
        pill
        :variant="'light-warning'"
      >
        {{ "escribe el nombre completo del alumno" }}
      </b-badge>
      <div class="mx-1">
        <div class="d-flex justify-content-center">
          <b-button
            @click="$bvModal.show('addReferenceModal')"
            size="sm"
            variant="primary"
          >
            Agregar datos de alumno
          </b-button>
        </div>

        <b-list-group class="my-1">
          <b-list-group-item
            v-for="(reference, index) in groupedBookReferences"
            :key="index"
            class="d-flex justify-content-center align-items-center list-group"
            @click="handleQuantityModal(reference)"
          >
            <div class="students-grid">
              <b-badge pill :variant="'light-info'">
                {{ JSON.parse(reference).name }}
              </b-badge>

              <b-badge
                pill
                :variant="'light-info'"
                v-if="currentStore.store_type === 'corporate_education'"
              >
                {{ JSON.parse(reference).email }}
              </b-badge>
              <b-badge
                pill
                :variant="'light-info'"
                v-if="currentStore.store_type === 'corporate_education'"
              >
                {{ JSON.parse(reference).contact }}
              </b-badge>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="mt-2 buttons">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-danger"
          @click="handleCloseModal()"
        >
          Cancelar
        </b-button>

        <b-button
          :disabled="bookReferences.length === 0"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="button"
          @click="addNameToReference()"
        >
          Continuar
        </b-button>
      </div>
    </b-modal>

    <add-reference-modal
      :handleCloseModal="handleCloseModalChild"
      :addReferenceOptionModals="addReferenceOptionModals"
    />

    <book-references-quantity-modal
      :selectedReference="selectedReference"
      :deleteProduct="deleteProductModals"
      :handleOkModals="handleOkModals"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import Ripple from "vue-ripple-directive"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import BookReferencesQuantityModal from "./BookReferencesQuantityModal.vue"
import AddReferenceModal from "./AddReferenceModal.vue"

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedReference: "",
    }
  },
  props: {
    storeData: {
      type: [Object, Array],
      validator: (p) => {
        return ["object"].indexOf(typeof p) !== -1 || p === null
      },
    },
  },
  components: {
    ToastificationContent,
    BookReferencesQuantityModal,
    AddReferenceModal,
  },
  computed: {
    ...mapGetters("pos", ["newCartItem", "bookReferences", "bookStoreModal"]),
    ...mapGetters("stores", ["currentStore"]),

    groupedBookReferences() {
      const uniqueReferences = new Set(this.bookReferences)
      return Array.from(uniqueReferences)
    },
  },
  methods: {
    ...mapMutations("pos", [
      "setReferenceToBook",
      "deleteBooksFromCart",
      "setBookReferences",
    ]),
    handleQuantityModal(reference) {
      this.selectedReference = reference
      this.$bvModal.show("bookReferencesQuantityModal")
    },
    addReferenceOptionModals() {
      this.$bvModal.hide("addReferenceModal")
      this.$bvModal.show("bookStoreModal")
    },
    addNameToReference() {
      if (this.bookReferences.length === 0) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Error",
            text: "Debes agregar al menos un alumno",
            icon: "EditIcon",
            variant: "danger",
          },
        })
        return
      }

      this.setReferenceToBook()

      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Agregado al carrito",
          text: "Tu selección se ha agregado correctamente al carrito",
          icon: "EditIcon",
          variant: "success",
        },
      })

      this.setBookReferences([])

      this.$bvModal.hide("bookStoreModal")
    },
    handleCloseModal() {
      this.bookReferences.forEach((reference) => {
        this.deleteBooksFromCart(this.newCartItem)
      })

      this.setBookReferences([])

      this.$bvModal.hide("bookStoreModal")
    },
    deleteProductModals() {
      this.$bvModal.show("bookStoreModal")
    },
    handleCloseModalChild() {
      this.$bvModal.hide("addReferenceModal")
      this.$bvModal.show("bookStoreModal")
    },
    handleOkModals() {
      this.$bvModal.show("bookStoreModal")
    },
  },
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.delete-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.students-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.list-group {
  position: relative;
  cursor: pointer;
}
</style>
