<template>
  <div class="ecommerce-searchbar my-1">
    <b-row>
      <b-col cols="12">
        <b-input-group class="input-group-merge">
          <b-form-input
            v-model="filters.q"
            placeholder="Buscar producto"
            class="search-product"
          />
          <b-input-group-append v-if="filters.q">
            <b-button
              variant="outline-warning"
              @click="clearSearchbarAndResetSearch()"
            >
              Borrar
            </b-button>
          </b-input-group-append>
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  props: {
    clearSearchbarAndResetSearch: {
      type: Function,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
};
</script>