<template>
  <b-modal
    id="change-delivery-address"
    title="Introduce nueva dirección"
    class="modal-content"
    ok-variant="success"
    cancel-variant="outline-warning"
    ok-title="Continuar"
    cancel-title="Regresar"
    @ok="changeDeliveryAddress()"
    @cancel="$bvModal.show('check-delivery-address')"
  >
    <!-- <address-form-model
      info-type
      @getAddress="setAddressForEdit($event)"
    /> -->
    <google-address-form @setAddress="setAddressForEdit($event)"></google-address-form>
  </b-modal>
</template>

<script>

import { mapActions } from 'vuex';

import GoogleAddressForm from '@/@core/components/GoogleAddressForm.vue'

export default {
  data() {
    return {
      optionsLocal: JSON.parse(localStorage.getItem('userData')),
    };
  },
  components: {
    GoogleAddressForm,
  },
  methods: {
    ...mapActions('users', ['updateCustomerAddress']),

    setAddressForEdit(x) {
      this.address_attributes = x
      this.optionsLocal.customer.address = x
    },
    changeDeliveryAddress() {
      this.address_attributes.id = this.optionsLocal.customer.address.id
      this.updateCustomerAddress({
        id: this.optionsLocal.customer.id,
        customer: {
          address_attributes: this.address_attributes,
        },
      })
        .then(() => {
          this.$bvModal.show('select-payment-method')
        })
        .catch(error => {
          const baseMsg = error.response.data.messages?.base ?? null
          if (baseMsg != null) {
            this.$swal({
              title: 'ERROR!',
              text: `${error.response.data.messages.base[0]}`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-content {
    background-color: #1B243D;
    .modal-body {
      padding: 0rem;
      backdrop-filter: #1B243D;
      z-index: 100;
    }
  }
</style>