<template>
  <b-card class="product-card mb-0" no-body text-variant="primary">
    <!-- Product Details - Photo -->
    <div class="product-image" :class="{ 'mb-1': itemView === 'list-view' }">
      <b-img
        :alt="`${product.name}-${product.id}`"
        fluid
        rounded
        :src="`${product.logo}`"
        width="80%"
        @click="() => $refs.imageModal.show()"
      />

      <b-button
        :variant="
          selectedStoreProduct.inventory === '0.0' ? 'secondary' : 'success'
        "
        class="btn-cart-small"
        :disabled="selectedStoreProduct.inventory === '0.0'"
        @click.stop="addToCart(product, selectedStoreProduct)"
      >
        <i class="fa fa-lg fa-cart-plus" />
      </b-button>

      <b-modal ref="imageModal" centered hide-footer>
        <b-img fluid :src="`${product.logo}`" alt="Image" />
      </b-modal>
    </div>

    <!-- Product Details - Name, variant and price -->
    <div class="p-1">
      <div
        v-if="product.store_products_attributes.length >= 1"
        class="item-wrapper"
      >
        <div class="item-cost">
          <h5 class="item-price text-body d-flex justify-content-between">
            <span class="mr-1"
              >${{ selectedStoreProduct.unit_price | money }}</span
            >
          </h5>
        </div>
      </div>

      <b-card-text class="bold line-clamp-6" text-variant="primary">
        {{ product.name }}
        <span class="text-muted">
          {{ product.variant }}
        </span>
      </b-card-text>

      <div class="item-wrapper">
        <div class="item-rating">
          <nutri-score
            v-if="product.is_edible"
            :nutritional-info="product.nutritional_info"
            :class="{ 'mt-1': itemView === 'list-view' }"
          />
        </div>
      </div>

      <div
        v-if="product.store_products_attributes.length === 1"
        class="item-wrapper"
      >
        <div class="item-units">
          <!-- <h5 v-if="product.inventory>0" class="item-units text-success d-flex justify-content-between">
              <span class="mr-1">Disponibles</span> {{ product.inventory }}
            </h5>-->
          <h5
            v-if="selectedStoreProduct.inventory === '0.0'"
            class="item-units text-danger d-flex justify-content-between"
          >
            <span class="mr-1">Agotado</span>
          </h5>
        </div>
      </div>
    </div>

    <div v-if="product.store_products_attributes.length > 1" class="mb-1">
      <span class="text-white"> * Elige una opción: </span>
      <b-form-select v-model="selectedStoreProduct" @click.native.stop>
        <b-form-select-option
          v-for="(store_product, index) in product.store_products_attributes"
          :key="index"
          :value="store_product"
        >
          <span
            v-for="(
              variant, n
            ) in store_product.store_product_variant_attributes"
            :key="`row-${n}`"
          >
            {{ variant.variant_option_attributes.option_name }}
          </span>
        </b-form-select-option>
      </b-form-select>
    </div>

    <!-- Product Actions -->
    <div
      class="text-center d-flex justify-content-center product-buttons mt-auto"
    >
      <div class="item-wrapper sr-only">
        <!-- 🐔 -->
      </div>

      <b-button
        variant="secondary"
        size="sm"
        class="w-100"
        v-if="
          product.store_products_attributes.length >= 1 &&
          selectedStoreProduct.bestPromotion
        "
        @click="handlePromotionsModal(selectedStoreProduct)"
      >
        <feather-icon icon="EyeIcon" class="mr-50" />
        <span class="btn-text">Ver promoción</span>
      </b-button>

      <b-button
        variant="secondary"
        size="sm"
        class="w-100"
        @click="handleProductDetails(product)"
      >
        <feather-icon icon="EyeIcon" class="mr-50" />
        <span class="btn-text">Descripción</span>
      </b-button>

      <b-button
        v-if="product.store_products_attributes.length >= 1"
        :variant="
          selectedStoreProduct.inventory === '0.0' ? 'secondary' : 'success'
        "
        :disabled="selectedStoreProduct.inventory === '0.0'"
        class="btn-cart w-100"
        @click.stop="addToCart(product, selectedStoreProduct)"
        size="sm"
      >
        <feather-icon icon="ShoppingCartIcon" class="mr-50" />
        <span class="btn-text">Añadir a carrito</span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import vSelect from "vue-select"
import NutriScore from "../NutriScore.vue"

export default {
  components: {
    NutriScore,
    vSelect,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    addToCart: {
      type: Function,
      required: true,
    },
    handleProductDetails: {
      type: Function,
      required: true,
    },
    handlePromotionsModal: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      itemView: "grid-view",
      selectedStoreProduct: this.product.store_products_attributes[0],
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.scrollable-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
}

.btn-cart-small {
  display: block;
  position: absolute;
  border-radius: 50%;
  right: 20px;
  top: 20px;
  z-index: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-text {
  font-size: 0.8rem;
}

.btn-cart {
  display: none;
}

@media (min-width: 768px) {
  .btn-text {
    font-size: 1rem;
  }

  .btn-cart {
    display: block;
  }

  .btn-cart-small {
    display: none;
  }
}

.product-image {
  margin: 0 auto;
}

.product-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  a {
    font-size: 1rem;
  }

  @media (max-width: 680px) {
    font-size: 1rem;

    a {
      font-size: 0.9rem;
    }
  }
}

.bold {
  font-weight: 600;
}
</style>
