<template>
  <div>
    <b-row>
      <b-col cols="12">
        <gmap-autocomplete
          ref="googleAddress"
          class="mb-2"
          placeholder="Buscar dirección"
          :componentRestrictions="{ country: 'mx' }"
          @place_changed="setAddressForEdit($event)"
        />
        <GmapMap
          v-b-tooltip.hover.bottomleft
          title="Doble click para seleccionar dirección"
          @click="setMarker($event)"
          :center="center"
          :zoom="zoom"
          map-type-id="roadmap"
          :style="`width: ${width}; height: ${height}`"
        >
          <GmapMarker :position="marker" :clickable="true" :draggable="false" />
        </GmapMap>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete"
import { getGoogleMapsAPI } from "gmap-vue"

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    height: {
      type: String,
      default: "300px",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      marker: {},
      center: { lat: 25.6866142, lng: -100.3161126 },
      zoom: 10,
      address: {},
    }
  },
  methods: {
    getAddress(place) {
      const address = {
        name: "",
        state: "",
        country: "",
        city: "",
        street: "",
        ext_number: "",
        int_number: "",
        postal_code: "",
        lat: "",
        lng: "",
        suburb: "",
        url: "",
        formatted_address: "",
      }
      place.address_components.forEach((x) => {
        if (x.types.includes("administrative_area_level_1")) {
          address.state = x.long_name
        }
        if (x.types.includes("country")) {
          address.country = x.long_name
        }
        if (x.types.includes("city") || x.types.includes("locality")) {
          address.city = x.long_name
        }
        if (x.types.includes("street") || x.types.includes("route")) {
          address.street = x.long_name
        }
        if (
          x.types.includes("ext_number") ||
          x.types.includes("street_number")
        ) {
          address.ext_number = x.long_name
        }
        if (x.types.includes("postal_code")) {
          address.postal_code = x.long_name
        }
        if (
          x.types.includes("suburb") ||
          x.types.includes("sublocality_level_1")
        ) {
          address.suburb = x.long_name
        }
      })
      address.formatted_address = place.formatted_address
      address.lat = place.geometry.location.lat()
      address.lng = place.geometry.location.lng()
      address.url = place.url
      return address
    },
    setAddressForEdit(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.zoom = 16
      this.marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.address = this.getAddress(place)
      this.$emit("setAddress", this.address)
    },
    setMarker(event) {
      // goecode manual marker to get address address
      // eslint-disable-next-line
      const mapsapi = new getGoogleMapsAPI()
      // console.log(mapsapi.maps.Geocoder.prototype.geocode);
      // const geocoder = new google.gmapApi().maps.Geocoder
      const latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      mapsapi.maps.Geocoder.prototype.geocode(
        { location: latlng },
        (results, status) => {
          if (status === "OK") {
            if (results[1]) {
              this.$refs.googleAddress.$el.children[0].value =
                results[1].formatted_address
              this.address = this.getAddress(results[1])
              const lat = event.latLng.lat()
              const lng = event.latLng.lng()
              this.address.google_maps_url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
              this.marker = {
                lat,
                lng,
              }
              this.$emit("setAddress", this.address)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Introduzca la direccion manualmente",
                  icon: "EditIcon",
                  variant: "danger",
                },
              })
            }
          }
        }
      )
    },
  },
}
</script>

<style lang="scss">
.pac-target-input {
  color: #495057;
  background-color: #fff;
  border: 1px solid #404656;

  padding: 0.438rem 1rem;
  border-radius: 0.357rem;
  width: 100%;
}

.dark-layout {
  .pac-target-input {
    color: #b4b7bd;
    background-color: #283046;
    border: 1px solid #404656;
  }
}
</style>
