<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">{{ totalProducts }} resultados</div>
            </div>
            <div class="view-options d-flex">
              <!-- Item View Radio Button Group  -->
              <b-button
                class="mr-1"
                variant="primary"
                @click="handlePurchase()"
              >
                <span>Finalizar compra</span>
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="ml-50"
                  size="15"
                  :badge="cartTotalProducts"
                />
              </b-button>

              <shopping-cart-modal :products="products" />

              <!-- Select Payment method -->
              <payment-method-modal
                :storeData="storeData"
                :completeOnlineOrder="completeOnlineOrder"
                :storeAccount="storeAccount"
                :loading="loading"
              />

              <!-- Finish CoDi payment -->
              <codi-payment-modal
                :loading="loading"
                :completeOnlineOrder="completeOnlineOrder"
                :order="order"
                :handleCancelCodiModal="handleCancelCodiModal"
              />

              <!-- CoDi info -->
              <codi-info-modal />

              <!-- Delivery Address -->
              <delivery-address-modal />

              <!--
              <b-button
                class="mr-1"
                variant="primary"
                @click="$bvModal.show('scanner')"
              >
                <i class="fas fa-barcode" />
              </b-button>
              -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <wall-eate-searchbar
      :filters="filters"
      :clearSearchbarAndResetSearch="clearSearchbarAndResetSearch"
    />

    <!-- Phone credits and services -->
    <services-grid-online />

    <!-- Products -->
    <wall-eate-product
      :products="products"
      :groupedProducts="groupedProducts"
      :itemView="itemView"
      :storeData="storeData"
    />

    <!-- Pagination -->
    <section v-if="totalProducts > filters.perPage">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :categories="categories"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :products="products"
      />
    </portal>

    <b-modal
      id="codi-qrcode"
      title="Código QR"
      size="lg"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="p-2 d-flex flex-column align-items-center justify-content-center">
        <p class="mb-2">
          Abre tu aplicación bancaria, dirígete a la sección de CoDi y escanea el código QR. También puedes guardar o hacer una captura de pantalla y subirla a tu aplicación bancaria dentro de la sección de CoDi.
        </p>
        <b-img :src="codiQrcode" alt="CoDi QR Code" fluid class="rounded" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from "underscore"
import { mapActions, mapGetters, mapMutations } from "vuex"

import ShopLeftFilterSidebar from "@/views/e-commerce/e-commerce-shop/ECommerceShopLeftFilterSidebar.vue"
import AddressFormModel from "@/@core/components/CustomerAddressFormModel.vue"
import ServicesGridOnline from "@/views/pos/ServicesGridOnline.vue"
import CodiInfoModal from "@/@core/components/WallEateCommerce/CodiInfoModal.vue"
import CodiPaymentModal from "@/@core/components/WallEateCommerce/CodiPaymentModal.vue"
import PaymentMethodModal from "@/@core/components/WallEateCommerce/PaymentMethodModal.vue"
import ShoppingCartModal from "@/@core/components/WallEateCommerce/ShoppingCartModal.vue"
import WallEateSearchbar from "@/@core/components/WallEateCommerce/WallEateSearchbar.vue"
import DeliveryAddressModal from "@/@core/components/WallEateCommerce/DeliveryAddressModal.vue"
import WallEateProduct from "@/@core/components/WallEateCommerce/WallEateProduct.vue"

import router from "@/router"
import { watch } from "@vue/composition-api"
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app"
import {
  useShopFiltersSortingAndPagination,
  useShopUiGrid,
  useShopRemoteData,
} from "@/views/stores/useStoreProducts"
import { useEcommerceUi } from "@/views/e-commerce/useEcommerce"

export default {
  components: {
    // COMPONENTS
    AddressFormModel,
    ServicesGridOnline,
    CodiInfoModal,
    CodiPaymentModal,
    PaymentMethodModal,
    ShoppingCartModal,
    WallEateSearchbar,
    DeliveryAddressModal,
    WallEateProduct,

    // SFC
    ShopLeftFilterSidebar,

    // Walleat
    // StreamBarcodeReader,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(localStorage.getItem("userData")),
      profileFile: null,
      loading: false,
      order: {
        cel_number: null,
      },
      codiQrcode: null,
    }
  },
  setup() {
    const { filters, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const { itemView, itemViewOptions, totalProducts } = useShopUiGrid()

    const {
      products,
      groupedProducts,
      storeData,
      storeAccount,
      fetchStore,
      fetchStoreAccount,
      fetchOnlineStoreProducts,
      categories,
      fetchCategories,
    } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const addProductsPromotion = (products) => {
      products.forEach((product) => {
        let bestPromotion = null
        bestPromotion = getBestPromotion(product)

        const condition = bestPromotion ? true : false
        if (condition) {
          getApplicablePromotion(product, bestPromotion)
        }
      })
    }

    const getApplicablePromotion = (product, bestPromotion) => {
      const index = products.value.indexOf(product)
      products.value[index].bestPromotion = bestPromotion
    }

    const getBestPromotion = (product) => {
      const product_totals = []
      const promotion_ids = []
      let promotionItem = null
      const unit_price = product.unit_price
      if (product.promotion_attributes.length > 0) {
        product.promotion_attributes.forEach((promotion) => {
          if (
            promotion.is_canceled === false &&
            promotion.is_published === true &&
            Date.now() / 1000 >
              new Date(promotion.begins_at).getTime() / 1000 &&
            Date.now() / 1000 < new Date(promotion.ends_at).getTime() / 1000
          ) {
            const unitsToNeedsToBuyRatio = Math.floor(
              promotion.needs_to_buy / promotion.needs_to_buy
            )
            const product_total_temp =
              promotion.promo_type === "reward_points"
                ? unit_price * promotion.needs_to_buy
                : promotion.promo_type === "price_discount"
                ? unit_price *
                  (1 - promotion.discount / 100) *
                  promotion.needs_to_buy
                : promotion.promo_type === "free_product"
                ? unit_price *
                  (promotion.needs_to_buy -
                    unitsToNeedsToBuyRatio * promotion.gets_you)
                : unit_price * promotion.needs_to_buy

            product_totals.push(product_total_temp / promotion.needs_to_buy) // New unit price based on promotion
            promotion_ids.push(promotion.id)
          }
        })
        promotionItem = product.promotion_attributes.find(
          (i) =>
            i.id ===
            promotion_ids[product_totals.indexOf(Math.min(...product_totals))]
        )
      }
      return promotionItem
    }

    const groupByProduct = (groupedProducts) => {
      // Create an object to store the grouped elements
      const _groupedProducts = {}

      // Iterate through the original array
      groupedProducts.value.forEach((element) => {
        const productId = element.product_attributes.id

        // If the product ID is not in the groupedProducts object, create an entry for it
        if (!_groupedProducts[productId]) {
          _groupedProducts[productId] = {
            ...element.product_attributes,
            store_products_attributes: [],
          }
        }

        // Add the store information to the grouped product
        _groupedProducts[productId].store_products_attributes.push({
          id: element.id,
          inventory: element.inventory,
          description: element.description,
          is_private: element.is_private,
          promotion_attributes: element.promotion_attributes,
          store_product_variant_attributes:
            element.store_product_variant_attributes,
          unit_price: element.unit_price,
          bestPromotion: element.bestPromotion,
          created_at: element.created_at,
          updated_at: element.updated_at,
        })
      })

      // Convert the groupedProducts object into an array
      const groupedArray = Object.values(_groupedProducts)

      groupedProducts.value = groupedArray
    }

    const fetchAcceptedPaymentTypes = _.debounce(function () {
      fetchStore(router.currentRoute.params.store_id).then((response) => {
        storeData.value = response
      })
    }, 500)

    const fetchStripeStoreAccount = _.debounce(function () {
      fetchStoreAccount({ id: router.currentRoute.params.store_id }).then(
        (response) => {
          storeAccount.value = response.store_account
        }
      )
    }, 500)

    const fetchShopProducts = _.debounce(function () {
      if (
        /^\d*$/.test(filters.value.q) &&
        filters.value.q !== null &&
        filters.value.q !== ""
      ) {
        const barcodeWithOutLastDigit = filters.value.q.substring(
          0,
          filters.value.q.length - 1
        )
        fetchOnlineStoreProducts({
          by_sku: Number(barcodeWithOutLastDigit) || null,
          by_category: filters.value.categories || null,
          by_active_status: true,
          by_nutritional_info: filters.value.nutriScore || null,
          by_store: router.currentRoute.params.store_id,
          meta: {
            pagination: {
              page: filters.value.page,
              per_page: filters.value.perPage,
            },
          },
        }).then((response) => {
          products.value = []
          products.value = response.data
          addProductsPromotion(products.value)
          groupedProducts.value = products.value
          groupByProduct(groupedProducts)
          totalProducts.value = response.meta.pagination.total_objects
        })
      } else if (filters.value.q !== null && filters.value.q !== "") {
        fetchOnlineStoreProducts({
          by_name: filters.value.q || null,
          by_category: filters.value.categories || null,
          by_active_status: true,
          by_nutritional_info: filters.value.nutriScore || null,
          by_store: router.currentRoute.params.store_id,
          meta: {
            pagination: {
              page: filters.value.page,
              per_page: filters.value.perPage,
            },
          },
        }).then((response) => {
          products.value = []
          products.value = response.data
          addProductsPromotion(products.value)
          groupedProducts.value = products.value
          groupByProduct(groupedProducts)
          totalProducts.value = response.meta.pagination.total_objects
        })
      } else if (filters.value.q === null || filters.value.q === "") {
        fetchOnlineStoreProducts({
          by_name: filters.value.q || null,
          by_category: filters.value.categories || null,
          by_active_status: true,
          by_nutritional_info: filters.value.nutriScore || null,
          by_store: router.currentRoute.params.store_id,
          meta: {
            pagination: {
              page: filters.value.page,
              per_page: filters.value.perPage,
            },
          },
        }).then((response) => {
          products.value = []
          products.value = response.data
          addProductsPromotion(products.value)
          groupedProducts.value = products.value
          groupByProduct(groupedProducts)
          totalProducts.value = response.meta.pagination.total_objects
        })
      }
    }, 500)

    const fetchProductsCategories = () => {
      fetchCategories({
        by_active_status: true,
        by_store: router.currentRoute.params.store_id,
        meta: {
          pagination: {
            per_page: 10000,
          },
        },
      }).then((response) => {
        categories.value = response.data
      })
    }

    fetchAcceptedPaymentTypes()
    fetchStripeStoreAccount()
    fetchShopProducts()
    fetchProductsCategories()

    watch(
      [filters, sortBy],
      () => {
        fetchShopProducts()
      },
      {
        deep: true,
      }
    )

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUiGrid
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteDataf
      products,
      groupedProducts,
      storeData,
      storeAccount,
      categories,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  computed: {
    ...mapGetters("pos", [
      "cartTotal",
      "cart",
      "cartTotalProducts",
      "productPromotion",
      "newCartItem",
      "booksWithReference",
    ]),
    ...mapGetters("stores", ["currentStore"]),
  },
  destroyed() {
    this.deleteReferenceToBook()
    this.emptyCart()
  },
  methods: {
    ...mapActions("stores", ["fetchOnlineStoreProducts"]),
    ...mapActions("pos", ["emptyCart"]),
    ...mapActions("orders", ["dispatchOnlineOrder"]),
    ...mapActions("emidaProducts", ["fetchEmidaProducts"]),
    ...mapActions("stripe", ["checkoutBankTransfer", "checkoutBankCard"]),
    ...mapMutations("users", ["updateUserData"]),
    ...mapMutations("pos", ["deleteBooksFromCart", "deleteReferenceToBook"]),

    handlePurchase() {
      if (this.cartTotalProducts > 0) {
        this.$bvModal.show("finish-purchase")
      } else {
        this.$swal({
          text: "No hay productos en el carrito",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        })
      }
    },

    handleCancelCodiModal() {
      this.$bvModal.show("select-payment-method")
    },

    concatenateVariantNames(variants) {
      return variants
        .map((variant) => variant.variant_option_attributes.option_name)
        .join(" - ")
    },
    setAddressForEdit(x) {
      this.address_attributes = x
      this.optionsLocal.customer.address = x
    },
    clearSearchbarAndResetSearch() {
      this.filters.q = ""
      this.products = []
    },
    // onDecode(result) {
    //  this.filters.q = result
    //  this.$bvModal.hide('scanner')
    // },
    eliminarDiacriticos(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    },
    handlePaymentWithCash(res) {
      this.$swal({
        text: "Venta exitosa!",
        title:
          "Tu orden será procesada en breve, deberás pagar al momento en que se te entreguen los productos",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      })
    },
    handlePaymentWithBankCard(res) {
      this.$swal({
        title: "Generando pago...",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })
      this.checkoutBankCard({
        checkout_session: {
          store_account_id: this.storeAccount.id,
          online_pending_order_id: res.id,
          price: JSON.parse(res.pending_order).total,
          success_url: window.location.href,
          cancel_url: window.location.href,
        },
      })
        .then((res) => {
          this.$swal.close()
          window.open(res.session_url, "_blank")
        })
        .catch((e) => {
          const messaageError = e.response.data.messages
          this.$swal({
            title: "ERROR!",
            text: `${messaageError}`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
        })
    },
    handlePaymentWithBankDeposit(res) {
      this.$swal({
        title: "Generando pago...",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      this.checkoutBankTransfer({
        checkout_session: {
          store_account_id: this.storeAccount.id,
          online_pending_order_id: res.id,
          price: JSON.parse(res.pending_order).total,
          success_url: window.location.href,
          cancel_url: window.location.href,
        },
      })
        .then((res) => {
          this.$swal.close()
          window.open(res.session_url, "_blank")
        })
        .catch((e) => {
          const messaageError = e.response.data.messages
          this.$swal({
            title: "ERROR!",
            text: `${messaageError}`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
        })
    },
    handlePaymentWithWalleat(res) {
      const qrcode = res.qrcode

      this.$swal.close()

      if (qrcode) {
        this.codiQrcode = qrcode
        this.$bvModal.show("codi-qrcode")
      } else {
        this.$swal({
          title: "Cobro solicitado!",
          text: "Si no cuentas con fondos suficientes en tu cuenta con el vendedor, en unos momentos más llegará una notificación en tu aplicación bancaria. Autoriza el cobro en tu celular durnte los primeros 15 minutos para poder procesar tu compra, de otra forma se cancelará automáticamente la transacción",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        })
      }

      this.$bvModal.hide("complete-codi-payment")
    },
    handlePaymentWithRewartPoints(res) {
      this.$swal({
        title: "Venta exitosa!",
        text: "Tu orden será procesada en breve. Si no cuentas con puntos Walleat suficientes en unos momentos más llegará una notificación en tu aplicación bancaria. Autoriza el cobro en tu celular para poder procesar tu compra",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      })
    },
    setOrder(paymentType, selectedIntent) {
      let order = {
        codi_response_type: selectedIntent,
        store_id: this.$route.params.store_id,
        cel_number: this.order.cel_number,
        from_store_id: this.$route.params.from_store_id,
        order_store_products_attributes: this.cart.map((product) => ({
          store_product_id: product.id,
          units: product.units,
        })),
        order_payments_attributes: [
          {
            payment_type: paymentType,
            amount: this.cartTotal,
          },
        ],
      }

      if (
        this.currentStore.store_type === "book_store" ||
        this.currentStore.store_type === "corporate_education"
      ) {
        order.grouped_order_store_products_attributes =
          this.booksWithReference.map((reference) => ({
            reference_json: reference.reference,
            store_products: JSON.stringify(reference.storeProducts),
          }))
      }

      return order
    },
    completeOnlineOrder(paymentType, selectedIntent = null) {
      this.loading = true

      const order = this.setOrder(paymentType, selectedIntent)

      const paymentHandlers = {
        cash: this.handlePaymentWithCash,
        bankcard: this.handlePaymentWithBankCard,
        bank_deposit: this.handlePaymentWithBankDeposit,
        walleat: this.handlePaymentWithWalleat,
        reward_points: this.handlePaymentWithRewartPoints,
        codi: this.handlePaymentWithWalleat,
      }

      const paymentHandler = paymentHandlers[paymentType]

      if (paymentType === "codi") {
        this.$swal({
          title: "Generando pago...",
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          },
        })
      }

      this.dispatchOnlineOrder({ order })
        .then((res) => {
          paymentHandler.call(this, res)
          this.loading = false
          this.$bvModal.hide("finish-purchase")
          this.$bvModal.hide("select-payment-method")
          this.emptyCart()
          this.deleteReferenceToBook()
        })
        .catch((error) => {
          this.$bvModal.hide("select-payment-method")
          this.$bvModal.hide("complete-codi-payment")
          this.$bvModal.hide("finish-purchase")
          this.loading = false
          const msg = error.response.data.messages[0]
            ? error.response.data.messages[0]
            : ""

          this.$swal({
            title: "ERROR!",
            text: `${msg}. Si no lo has hecho ACTIVA CODI EN TU APLICACIÓN BANCARIA`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
        })
        .finally(() => {
          this.loading = false
          this.$bvModal.hide("complete-codi-payment")
        })
    },
  },
}
</script>

<style lang="scss">
.modal-content {
  background-color: #1b243d;
  .modal-body {
    padding: 0rem;
    backdrop-filter: #1b243d;
    z-index: 100;
  }
}

@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.cart-modal {
  max-height: 500px;
  overflow-y: auto;
  border-radius: 10px 10px 10px 10px;
}
.card-img-top {
  max-width: 225px;
}

.space {
  width: 15px;
  height: auto;
  display: inline-block;
}
</style>
