<template>
  <b-modal
    id="select-payment-method"
    title="Selecciona el método de pago"
    class="modal-content"
    ok-variant="outline-warning"
    cancel-variant="outline-danger"
    ok-title="Regresar"
    ok-only
    @ok="$bvModal.show('finish-purchase')"
  >
    <div class="payments-grid p-2">
      <div
        v-if="
          storeData.accepted_payment_types &&
          (storeData.accepted_payment_types.includes('walleat') || storeData.accepted_payment_types.includes('codi')) 
        "
        class="payment shadow-lg"
      >
        <b-button
          variant="outline-primary"
          class="payment-button w-100 h-100"
          :disabled.sync="loading"
          @click="showCodiPaymentModal()"
        >
          <b-img :src="require('@/assets/images/logo/CODI.svg')" width="90" />
          <span class="button-text p-1">Codi</span>
        </b-button>
      </div>

      <div
        v-if="
          storeData.accepted_payment_types &&
          storeData.accepted_payment_types.includes('cash')
        "
        class="payment shadow-lg"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="payment-button w-100 h-100"
          :disabled.sync="loading"
          @click="completeOnlineOrder('cash')"
        >
          <b-img :src="require('@/assets/images/logo/CASH.svg')" width="80" />
          <b-spinner v-if="loading" large />
          <span class="button-text p-1">Efectivo</span>
        </b-button>
      </div>

      <div
        v-if="
          storeData.accepted_payment_types &&
          storeData.accepted_payment_types.includes('bankcard') &&
          storeAccount
        "
        class="payment shadow-lg"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="payment-button w-100 h-100"
          :disabled.sync="loading"
          @click="completeOnlineOrder('bankcard')"
        >
          <b-img
            :src="require('@/assets/images/logo/bank_card.png')"
            width="80"
          />
          <b-spinner v-if="loading" large />
          <span class="button-text p-1">Tarjeta</span>
        </b-button>
      </div>

      <div
        v-if="
          storeData.accepted_payment_types &&
          storeData.accepted_payment_types.includes('reward_points')
        "
        class="payment shadow-lg"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="payment-button w-100 h-100"
          :disabled.sync="loading"
          @click="completeOnlineOrder('reward_points')"
        >
          <b-img
            :src="require('@/assets/images/logo/reward_points.png')"
            width="80"
          />
          <b-spinner v-if="loading" large />
          <span class="button-text p-1">Puntos de recompensa</span>
        </b-button>
      </div>

      <div
        v-if="
          storeData.accepted_payment_types &&
          storeData.accepted_payment_types.includes('bank_deposit') &&
          storeAccount
        "
        class="payment shadow-lg"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="payment-button w-100 h-100"
          :disabled.sync="loading"
          @click="completeOnlineOrder('bank_deposit')"
        >
          <b-img
            :src="require('@/assets/images/logo/bank_transfer.png')"
            width="80"
          />
          <b-spinner v-if="loading" large />
          <span class="button-text p-1">Transferencia/depósito</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    storeData: {
      type: [Object, Array],
      required: true,
    },
    completeOnlineOrder: {
      type: Function,
      required: true,
    },
    storeAccount: {
      type: [Object, Array],
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    showCodiPaymentModal() {
      this.$bvModal.hide("select-payment-method");
      this.$bvModal.hide("finish-purchase");
      this.$bvModal.show("complete-codi-payment");
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  background-color: #444c66;
  width: 100%;
  height: 100%;
}

.button-text {
  text-overflow: ellipsis;
}

@media (min-width: 768px) {
  .responsive-text {
    font-size: 1.2rem;
  }
}

.dark-layout {
  .payment {
    background-color: #0e1322;
  }
}

.payments-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  .payment {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transition: transform 0.2s ease;
    min-height: 130px;

    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }

    .payment-button {
      font-size: 1rem !important;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
      font-size: 1rem !important;

      img {
        width: 70px;
      }
    }
  }

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);

    .payment {
      .payment-button {
        font-size: 1.2rem !important;
      }
    }
  }

  @media (min-width: 768px) {
    .payment {
      .payment-button {
        img {
          max-width: min-content;
        }
      }
    }
  }
}
</style>
