<template>
  <section class="products-grid">
    <VariantProductCard
      v-for="product in groupedProducts"
      :key="product.id"
      :product="product"
      :addToCart="addToCart"
      :handleProductDetails="handleProductDetails"
      :handlePromotionsModal="handlePromotionsModal"
    />

    <BooksModal :storeData="storeData" />
    <ProductDetailsModal :product="selectedProd" />
    <ProductPromotionsModal :selectedStoreProduct="selectedStoreProduct" />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

import VariantProductCard from "./VariantProductCard.vue"
import ProductDetailsModal from "./ProductDetailsModal.vue"
import BooksModal from "@/@core/components/WallEateCommerce/BooksModal.vue"
import ProductPromotionsModal from "@/@core/components/WallEateCommerce/ProductPromotionsModal.vue"

export default {
  data() {
    return {
      selectedProd: null,
      selectedStoreProduct: null,
    }
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    groupedProducts: {
      type: Array,
      default: () => [],
    },
    itemView: {
      type: String,
      default: "grid-view",
    },
    storeData: {
      type: [Object, Array],
      validator: (p) => {
        return ["object"].indexOf(typeof p) !== -1 || p === null
      },
    },
  },
  components: {
    BooksModal,
    VariantProductCard,
    ProductDetailsModal,
    ProductPromotionsModal,
  },
  computed: {
    ...mapGetters("pos", ["cart", "selectedProduct"]),
    ...mapGetters("stores", ["currentStore"]),
  },
  methods: {
    ...mapMutations("pos", ["setSelectedProduct", "setBookReferences"]),
    ...mapActions("pos", ["addProductToCart", "checkPromotions"]),

    addToCart(product, storeProduct) {
      this.setBookReferences([])
      if (storeProduct) {
        this.setSelectedProduct(
          this.products.find(
            (p) =>
              p.product_attributes.id === product.id && p.id === storeProduct.id
          )
        )
      } else {
        this.setSelectedProduct(product)
      }

      this.checkPromotions({ data: [{ ...this.selectedProduct }] })

      if (
        this.currentStore.store_type === "book_store" ||
        this.currentStore.store_type === "corporate_education"
      ) {
        this.$bvModal.show("bookStoreModal")
        return
      }
      this.addProductToCart({ data: [{ ...this.selectedProduct }] })
    },
    handleProductDetails(product) {
      this.selectedProd = product
      this.$bvModal.show(`product-details-modal`)
    },
    handlePromotionsModal(product) {
      this.selectedStoreProduct = product
      this.$bvModal.show(`product-promotion-modal`)
    },
  },
}
</script>

<style lang="scss" scoped>
.products-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 1rem 0;

  .product-card {
    display: flex;
    flex-direction: column;
    padding: 1rem 10px;
  }

  @media (min-width: 350px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 580px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1380px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1536px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1800px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(7, 1fr);
  }
}
</style>
